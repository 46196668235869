import { jsPDF } from "jspdf";

function imageToCanvas(url) {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    
    const img = new Image();
    img.crossOrigin = 'anonymous'; // Necessary for cross-origin images
    
    img.onload = function() {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(img, 0, 0);    
      resolve(canvas);
    };
    
    img.onerror = reject;
    
    img.src = url;
  });
}

export async function make(assignment){
  const doc = new jsPDF('l', 'pt', 'a4')// jsPDF(orientation, unit, format)
  let due = new Date(assignment.due_at)
  let duedate = due.getDate()+"/"+due.getMonth()+"/"+due.getFullYear()
  let noCalcImg = await imageToCanvas('/resources/calc-no.png')
  let calcImg = await imageToCanvas('/resources/calc.png')
  doc.setFontSize(12);
  doc.text(assignment.title+" - due "+duedate, 30, 40);
  doc.setFontSize(8);
  doc.setLineWidth(0.2);
  let pageHeight= doc.internal.pageSize.height
  let yoffset = 80
  let decache = new Date().getTime()
  let scale = 0.4

  const makeQuestion = ({doc,item,x,y,img,counter})=>{
    let title = `Question ${counter} - ( ${item.marks} marks available )`
    doc.text(title,x, y);
    doc.addImage(img, '', x, y+10, 947*scale, 535*scale)
    doc.rect(x, y+10, 947*scale, 535*scale);
    if(item.calculator){
      doc.addImage(calcImg, '', x+355, y+15, 20, 20)
    }else{
      doc.addImage(noCalcImg, '', x+355, y+15, 20, 20)
    }
  }

  for(let index=0; index<assignment.questions.length; index++){
    let item = assignment.questions[index]
    let imgCanvas = await imageToCanvas(`/images/questions/question${item.id}.png?u=${decache}`)   
    let counter = index+1
    if(counter%4 == 1){// top left
      makeQuestion({doc,counter,x:32,y:yoffset,img:imgCanvas,item})
    }
    if(counter%4 == 2){// top right
      makeQuestion({doc,counter,x:420,y:yoffset,img:imgCanvas,item})
    }
    if(counter%4 == 3){// bottom left
      makeQuestion({doc,counter,x:32,y:yoffset+280,img:imgCanvas,item}) 
    }
    if(counter%4 == 0){// bottom right
      makeQuestion({doc,counter,x:420,y:yoffset+280,img:imgCanvas,item})
      doc.addPage();
      yoffset = 50
    }
  }
  doc.save(assignment.title+".pdf");
  // doc.output('dataurlnewwindow');  
}

